import React, { useState, useEffect } from "react"
import _ from "lodash"
import styled from "styled-components"
import { Box, Paper, TextField, Button } from "@material-ui/core"
import SearchIcon from "@material-ui/icons/Search"
import { Loading, Select, Table } from "../../common/components"
import { ArrayUtils } from "../../common/utils"
import {
  useHospitals,
  useUsersCheckup,
  useExportExcel,
  useUsersExport,
  useUpdateHospital
} from "../../common/hooks"
import { AdminTransform } from "../../common/business/admin"
import CheckupMain from "@bit/weeravit.aptnesshealth.checkup/checkup.main.component"
// import hospitalData from '../../../template/hospital_sukavej'

const PaperStyled = styled(Paper)`
  padding: 0.7vw;
`

const DateTextStyled = styled(TextField)`
  margin-left: 1vw;
`

const PatientIDStyled = styled(TextField)`
  margin-left: 1vw;
`

const SearchButtonStyled = styled(Button)`
  margin-top: 1.5vh;
  margin-left: 1vw;
`

const ExportButtonStyled = styled(Button)`
  margin-top: 1.5vh;
  margin-left: 1vw;
`

const SearchIconStyled = styled(SearchIcon)`
  margin-right: 0.5vw;
`

const Wrapper = styled(Box)`
  max-height: ${props => (props.table ? "80vh" : "78.5vh")};
  overflow: auto;
`

const MyMain = () => {
  const hospitals = useHospitals()
  const { exportFile } = useExportExcel()
  const { values: checkupResult, get: getUsersCheckup } = useUsersCheckup()
  const { values: usersCheckupForExport, get: getUsersExport } = useUsersExport()
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true)
  const [tableLoading, setTableLoading] = useState(true)
  const [activeUser, setActiveUser] = useState(null)
  const [activeHospital, setActiveHospital] = useState({ id: "" })
  const [selectedDate, setSelectedDate] = useState(null)
  const [patientId, setPatientId] = useState(null)
  const [requestUsersCheckup, setRequestUsersCheckup] = useState(null)
  // const { isHospitalUpdate, updateHospital } = useUpdateHospital()

  useEffect(() => {
    if (!_.isEmpty(hospitals)) {
      setActiveHospital(hospitals[0])
      loadUsersCheckup(hospitals[0])
      
      // updateHospital({
      //   id: 'cPwnNtS2sQ1Dzh7l3lNG',
      //   data: {
      //     mapping: hospitalData.mapping,
      //     medical_checkups: hospitalData.medical_checkups,
      //     exports: hospitalData.exports
      //   }
      // })
    }
  }, [hospitals])

  useEffect(() => {
    if (!requestUsersCheckup) {
      return
    }
    const { startAt, isChanged } = requestUsersCheckup

    if (!isChanged) {
      return
    }

    if (isChanged && startAt === 1) {
      setLoading(true)
      setUsers([])
    }

    setTableLoading(true)
    getUsersCheckup(requestUsersCheckup)
  }, [requestUsersCheckup])

  useEffect(() => {
    if (!checkupResult.users) {
      return
    }

    if (requestUsersCheckup.startAt === 1) {
      setActiveUser(checkupResult.users[0])
    }

    setUsers([...users, ...checkupResult.users])
    setLoading(false)
    setTableLoading(false)
  }, [checkupResult])

  useEffect(() => {
    if (!_.isEmpty(usersCheckupForExport)) {
      exportFile({
        json: usersCheckupForExport,
        filename: `${activeHospital.name}_${selectedDate}`,
      })
      setLoading(false)
    }
  }, [usersCheckupForExport])

  const loadUsersCheckup = hospital => {
    setRequestUsersCheckup({
      hospitalId: hospital.id,
      checkupDate: selectedDate || null,
      startAt: 1,
      isChanged: true,
      patientId,
    })
  }

  const handleChangeHospital = e => {
    const hospital = _.find(hospitals, { id: e.target.value })
    setActiveHospital(hospital)
  }

  const handleDateChange = event => {
    setSelectedDate(event.target.value)
  }

  const handlePatientId = event => {
    setPatientId(event.target.value)
  }

  const handleSearch = () => {
    loadUsersCheckup(activeHospital)
  }

  const handleExport = () => {
    setLoading(true)
    getUsersExport({
      hospitalId: activeHospital.id,
      checkupDate: selectedDate
    })
  }

  const handleTableClick = ({ item, index }) => {
    setActiveUser(users[index])
  }

  const handleTableChangePage = ({ rowsPerPage, page, isChanged }) => {
    const startAt = page * rowsPerPage - (rowsPerPage - 1)
    setRequestUsersCheckup({
      ...requestUsersCheckup,
      startAt,
      isChanged,
    })
  }

  return (
    <Loading loading={loading}>
      <Box display="flex">
        <Box flexGrow={1} pt={2} pl={2} pr={2} display="inline-block">
          <PaperStyled>
            <Select
              label="เลือกโรงพยาบาล"
              items={ArrayUtils.toObjectWithKeyValue(hospitals, "id", "name")}
              defaultValue={activeHospital.id}
              onChange={handleChangeHospital}
            />
            <DateTextStyled
              label="กรอกวันที่ตรวจ"
              value={selectedDate}
              onChange={handleDateChange}
            />
            <PatientIDStyled
              label="Patient ID"
              value={patientId}
              onChange={handlePatientId}
            />
            <SearchButtonStyled
              variant="contained"
              color="primary"
              onClick={handleSearch}
            >
              <SearchIconStyled />
              ค้นหา
            </SearchButtonStyled>

            <ExportButtonStyled
              variant="contained"
              color="primary"
              onClick={handleExport}
              disabled={_.isEmpty(activeHospital.id) || _.isEmpty(selectedDate)}
            >
              Export
            </ExportButtonStyled>
          </PaperStyled>
        </Box>
      </Box>

      <Box display="flex">
        <Box width="375px" pt={2} pr={2} order={2} display="inline-block">
          <Paper>
            <Wrapper mobile>
              {activeUser && activeHospital && (
                <CheckupMain
                  isDrawerShow={false}
                  profile={{ name: activeUser.name }}
                  hospital={activeHospital}
                  checkup={AdminTransform.toCreateUsersCheckup(
                    null,
                    activeHospital,
                    activeUser
                  )}
                  xrayLinks={[]}
                  dicomLinks={[]}
                />
              )}
            </Wrapper>
          </Paper>
        </Box>

        <Box
          flexGrow={1}
          pt={2}
          pl={2}
          pr={2}
          order={1}
          display="inline-block"
          style={{ overflow: "auto" }}
        >
          <Paper>
            <Wrapper table>
              <Table
                data={users}
                onItemClick={handleTableClick}
                onChangePage={handleTableChangePage}
                loading={tableLoading}
              />
            </Wrapper>
          </Paper>
        </Box>
      </Box>
    </Loading>
  )
}

export default MyMain
