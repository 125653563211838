import React from "react"
import styled from "styled-components"
import { Container, Box, Paper, CircularProgress } from "@material-ui/core"

const ContainerStyled = styled(Container)`
  margin-top: 25vh;
`

const BoxStyled = styled(Box)`
  height: 20vh;
`

export default ({ children, loading = false }) => {
  if (loading) {
    return (
      <ContainerStyled maxWidth="sm">
        <Paper>
          <BoxStyled display="flex" justifyContent="center" alignItems="center">
            <CircularProgress disableShrink />
          </BoxStyled>
        </Paper>
      </ContainerStyled>
    )
  }

  return children
}
