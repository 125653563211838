import _ from "lodash"
import { PromiseUtils } from "../../utils"
import { ApiManager, StorageManager } from "../../managers"

class AuthenService {
  async verify({ username, pincode, secretCode }) {
    const [err, data] = await PromiseUtils.to(
      ApiManager.verify({ username, pincode, secretCode })
    )

    if (err) {
      throw err
    }

    StorageManager.saveAdminCredential(data.accessToken)
    return data
  }
}

export default AuthenService
