import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Paper, TextField, Box, Button, Container } from "@material-ui/core"
import { SnackbarWarning, Loading } from "../../common/components"
import { useLogin, useDatabase } from "../../common/hooks"
import { navigate } from "gatsby"

const PaperStyled = styled(Paper)`
  margin-top: 25vh;
`

const LoginButtonStyled = styled(Button)`
  margin-top: 2vh;
`

export default () => {
  const [loading, setLoading] = useState(false)
  const { save: saveLoginState } = useDatabase("login")

  const {
    values,
    handleUsernameChange,
    handlePincodeChange,
    isVerifySuccess,
    error,
    submitVerify,
  } = useLogin()

  useEffect(() => {
    if (isVerifySuccess) {
      saveLoginState(values)
      navigate("checkup-upload")
    }
  }, [isVerifySuccess])

  useEffect(() => {
    if (error) {
      setLoading(false)
    }
  }, [error])

  const handleSubmitVerify = () => {
    setLoading(true)
    submitVerify()
  }

  return (
    <Loading loading={loading}>
      <Container maxWidth="sm">
        <PaperStyled>
          <Box p={2}>
            <SnackbarWarning open={error ? true : false} message={error} />

            <TextField
              id="username"
              label="Username"
              margin="normal"
              variant="outlined"
              value={values.username}
              onChange={handleUsernameChange}
              fullWidth
            />

            <TextField
              id="pincode"
              label="Pincode by Google Authenticator"
              margin="normal"
              variant="outlined"
              type="password"
              value={values.pincode}
              onChange={handlePincodeChange}
              fullWidth
            />

            <LoginButtonStyled
              size="large"
              color="primary"
              variant="contained"
              fullWidth
              onClick={handleSubmitVerify}
            >
              Verify
            </LoginButtonStyled>
          </Box>
        </PaperStyled>
      </Container>
    </Loading>
  )
}
