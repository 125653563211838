import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import InboxIcon from "@material-ui/icons/MoveToInbox"
import GroupIcon from '@material-ui/icons/Group'
import { navigate } from "gatsby"

const Content = styled.div`
  width: 250;
`

const MyDrawer = ({ open }) => {
  const [drawerState, setDrawerState] = useState(open)

  useEffect(() => {
    setDrawerState(open)
  }, [open])

  const toggleDrawer = open => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }

    setDrawerState({ open })
  }

  const handleMenuCheckupUpload = () => {
    navigate("checkup-upload")
  }

  const handleMenuPreCheckupUpload = () => {
    navigate("pre-checkup-upload")
  }

  const handleMenuUserManagement = () => {
    navigate("user-management")
  }

  const sideList = () => (
    <Content
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem button onClick={handleMenuCheckupUpload}>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary="ผลตรวจ" />
        </ListItem>

        <ListItem button onClick={handleMenuPreCheckupUpload}>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary="รายการตรวจล่วงหน้า" />
        </ListItem>

        <ListItem button onClick={handleMenuUserManagement}>
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          <ListItemText primary="จัดการ user" />
        </ListItem>
      </List>
    </Content>
  )

  return (
    <Drawer open={drawerState.open} onClose={toggleDrawer(false)}>
      {sideList()}
    </Drawer>
  )
}

export default MyDrawer
