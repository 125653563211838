import _ from "lodash"
import { PromiseUtils } from "../../utils"
import AdminExportUtils from "./admin-export.utils"

class AdminService {
  constructor(repository) {
    this.repository = repository
  }

  login({ username, password }) {
    return new Promise((resolve, reject) => {
      this.repository
        .login({
          username,
          password,
        })
        .then(token => resolve(token))
        .catch(error => reject(error))
    })
  }

  logout() {
    return this.repository.logout()
  }

  isLogin() {
    const credentials = this.repository.getCredentials()

    if (!credentials.uid) {
      return false
    }

    return true
  }

  createHospital(hospital) {
    return new Promise((resolve, reject) => {
      this.repository
        .createHospital(hospital)
        .then(result => resolve(result))
        .catch(err => reject(err))
    })
  }

  createUsers(hospitalId, users) {
    return new Promise(async (resolve, reject) => {
      const [hospitalErr, hospital] = await PromiseUtils.to(
        this.repository.getHospital(hospitalId)
      )

      if (hospitalErr) {
        reject(hospitalErr)
        return
      }

      this.repository
        .createUsers(hospital, users)
        .then(result => resolve(result))
        .catch(err => reject(err))
    })
  }

  createPreCheckup(hospitalId, users) {
    return new Promise(async (resolve, reject) => {
      const [hospitalErr, hospital] = await PromiseUtils.to(
        this.repository.getHospital(hospitalId)
      )

      if (hospitalErr) {
        reject(hospitalErr)
        return
      }

      this.repository
        .createPreCheckup(hospital, users)
        .then(result => resolve(result))
        .catch(err => reject(err))
    })
  }

  async getHospitals() {
    const credential = this.repository.getCredentials()
    return await this.repository.getHospitalsByAdmin(credential.uid)
  }

  updateHospital(data) {
    return this.repository.updateHospital(data)
  }

  getUsersCheckupBy(props) {
    return Promise.all([this.repository.getUsersCheckupBy(props)])
  }

  async getUsersExport({ hospitalId, checkupDate }) {
    const [hospital, checkups] = await Promise.all([
      this.repository.getHospital(hospitalId),
      this.repository.getUsersCheckup({
        hospitalId,
        checkupDate,
      }),
    ])

    return checkups.map(checkup => {
      const keyMedicalCheckups = _.keyBy(checkup.medical_checkups, "title")
      const keyLabs = Object.entries(keyMedicalCheckups).reduce(
        (previous, [key, value]) => ({
          ...previous,
          [key]: {
            ...value,
            ..._.keyBy(value.labs, "title"),
          },
        }),
        {}
      )
      const keyCheckups = {
        ...checkup,
        ...keyLabs,
      }

      const orderCheckupsByASC = _.orderBy(
        hospital.exports[0],
        ["order"],
        ["asc"]
      )

      return Object.entries(orderCheckupsByASC).reduce(
        (previous, [, value]) => {
          const result = _.get(keyCheckups, value.from) || ""
          const replaces = _.get(value, "replaces") || []
          const replaced = AdminExportUtils.toReplace(result, replaces)

          return {
            ...previous,
            [value.to]: replaced,
          }
        },
        {}
      )
    })
  }

  deleteUserCheckup({ hospital = "", checkupDate = "" }) {
    return this.repository.deleteUserCheckup({
      hospital,
      checkupDate,
    })
  }
}

export default AdminService
