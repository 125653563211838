import React from "react"
import styled from "styled-components"

const SpacingStyled = styled.div`
  height: 2vh;
`

export default props => {
  return <SpacingStyled {...props} />
}
