import React from "react"
import XLSX from "xlsx"
import FileSaver from "file-saver"

const exportFile = ({
    json = [],
    filename = '',
    fileExtension = ".xlsx",
    fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
}) => {
  const ws = XLSX.utils.json_to_sheet(json)
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
  const data = new Blob([excelBuffer], { type: fileType })
  FileSaver.saveAs(data, `${filename}${fileExtension}`)
}

export default () => {
  return {
    exportFile,
  }
}
