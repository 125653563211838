import _ from "lodash"

export default {
  toReplace: (old = '', replaces = []) => {
    const replaced = replaces.reduce((previousWord, condition) => {
      const foundWord = _.get(condition, "found")
      const foundElseWord = _.get(condition, "foundElse")
      const replaceByNewWord = _.get(condition, "replace")

      if (foundWord &&
            foundWord === previousWord) {
        return replaceByNewWord
      }

      if (foundElseWord &&
            !_.isEmpty(previousWord) &&
            foundElseWord !== previousWord) {
        return replaceByNewWord
      }

      return previousWord
    }, old)

    return replaced
  },
}
