import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core"

const FormControlStyled = styled(FormControl)`
  min-width: 10vw;
`

const Component = props => {
  const { label = "", items = {}, defaultValue = "", onChange } = props
  const [value, setValue] = React.useState(defaultValue)

  const handleChange = e => {
    setValue(e.target.value)

    if (onChange !== undefined) {
      onChange(e)
    }
  }

  return (
    <FormControlStyled {...props}>
      <InputLabel>{label}</InputLabel>
      <Select value={value} onChange={handleChange}>
        {Object.entries(items).map(([key, value], index) => (
          <MenuItem key={index} value={key}>
            {value}
          </MenuItem>
        ))}
      </Select>
    </FormControlStyled>
  )
}

Component.propTypes = {
  label: PropTypes.string.isRequired,
  items: PropTypes.object,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
}

export default Component
