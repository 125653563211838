// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/weeravit/Documents/reactjs-workspace/web-admin-aptnesshealth/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-checkup-preview-js": () => import("/Users/weeravit/Documents/reactjs-workspace/web-admin-aptnesshealth/src/pages/checkup-preview.js" /* webpackChunkName: "component---src-pages-checkup-preview-js" */),
  "component---src-pages-checkup-upload-js": () => import("/Users/weeravit/Documents/reactjs-workspace/web-admin-aptnesshealth/src/pages/checkup-upload.js" /* webpackChunkName: "component---src-pages-checkup-upload-js" */),
  "component---src-pages-dashboard-js": () => import("/Users/weeravit/Documents/reactjs-workspace/web-admin-aptnesshealth/src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-index-js": () => import("/Users/weeravit/Documents/reactjs-workspace/web-admin-aptnesshealth/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("/Users/weeravit/Documents/reactjs-workspace/web-admin-aptnesshealth/src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-logout-js": () => import("/Users/weeravit/Documents/reactjs-workspace/web-admin-aptnesshealth/src/pages/logout.js" /* webpackChunkName: "component---src-pages-logout-js" */),
  "component---src-pages-pre-checkup-upload-js": () => import("/Users/weeravit/Documents/reactjs-workspace/web-admin-aptnesshealth/src/pages/pre-checkup-upload.js" /* webpackChunkName: "component---src-pages-pre-checkup-upload-js" */),
  "component---src-pages-user-management-js": () => import("/Users/weeravit/Documents/reactjs-workspace/web-admin-aptnesshealth/src/pages/user-management.js" /* webpackChunkName: "component---src-pages-user-management-js" */)
}

