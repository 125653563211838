import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { IconButton, Snackbar, SnackbarContent } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"

const SnackbarStyled = styled(SnackbarContent)`
  background-color: #43a047;
`

const SnackbarOverlay = ({ open = false, message = "" }) => {
  const [state, setState] = useState({
    open,
    message,
  })

  useEffect(() => {
    setState({
      ...state,
      open,
      message,
    })
  }, [open, message])

  const handleClose = e => {
    setState({
      ...state,
      open: false,
      message: "",
    })
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={state.open}
      onClose={handleClose}
    >
      <SnackbarStyled
        aria-describedby="client-snackbar"
        message={<span id="client-snackbar">{state.message}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </Snackbar>
  )
}

SnackbarOverlay.propTypes = {
  color: PropTypes.oneOf(["success", "warning"]),
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
}

export default SnackbarOverlay
