import _ from "lodash"

export default {
  wrapper: ({ firestore }) => {
    return {
      firestore,
      collection(name) {
        this.firestore = this.firestore.collection(name)
        return this
      },
      doc(id) {
        return _.isEmpty(id) ? "" : this.firestore.doc(id)
      },
      where({ col, operator, value, ignoreBy = false }) {
        if (!ignoreBy) {
          this.firestore = this.firestore.where(col, operator, value)
        }
        return this
      },
      startAfter({ doc, ignoreBy = false }) {
        if (!ignoreBy) {
          this.firestore = this.firestore.startAfter(doc)
        }
        return this
      },
      limit(number) {
        if (number !== -1) {
          this.firestore = this.firestore.limit(number)
        }
        return this
      },
      get() {
        return this.firestore.get()
      },
    }
  },
}
