import React, { useState, useEffect } from "react"
import _ from "lodash"
import styled from "styled-components"
import { Box, Paper, Button } from "@material-ui/core"
import { CloudUpload } from "@material-ui/icons"
import { Loading, SnackbarOverlaySuccess, Table } from "../../common/components"
import { useDatabase, useUploadCheckup } from "../../common/hooks"
import { AdminTransform } from "../../common/business/admin"
import CheckupMain from "@bit/weeravit.aptnesshealth.checkup/checkup.main.component"

const Wrapper = styled(Box)`
  max-height: ${props => (props.table ? "80vh" : "78.5vh")};
  overflow: auto;
`

const PaperStyled = styled(Paper)`
  padding: 0.7vw;
`

const CloudUploadStyled = styled(CloudUpload)`
  margin-left: 1vw;
`

export default props => {
  const { state: stateCheckupData, get } = useDatabase("checkup-upload")
  const { isCheckupUploaded, uploadCheckup } = useUploadCheckup()
  const [loading, setLoading] = useState(true)
  const [users, setUsers] = useState(null)
  const [activeUser, setActiveUser] = useState(null)
  const [activeHospital, setActiveHospital] = useState(null)

  useEffect(() => {
    get()
  }, [])

  useEffect(() => {
    if (stateCheckupData.id) {
      const { users, hospital } = stateCheckupData.value
      setUsers(users)
      setActiveUser(users[0])
      setActiveHospital(hospital)
    }
  }, [stateCheckupData])

  useEffect(() => {
    if (users && activeUser && activeHospital) {
      setLoading(false)
    }
  }, [users, activeUser, activeHospital])

  useEffect(() => {
    if (isCheckupUploaded) {
      setLoading(false)
    }
  }, [isCheckupUploaded])

  const handleUpload = () => {
    setLoading(true)
    uploadCheckup({
      users,
      hospitalId: activeHospital.id,
    })
  }

  const handleTableClick = ({ item, index }) => {
    setActiveUser(users[index])
  }

  return (
    <Loading loading={loading}>
      <Box display="flex">
        <Box flexGrow={1} pt={2} pl={2} pr={2} display="inline-block">
          <PaperStyled>
            <span>
              <Button
                variant="contained"
                color="default"
                onClick={handleUpload}
                disabled={isCheckupUploaded}
              >
                อัพโหลด
                <CloudUploadStyled />
              </Button>

              <SnackbarOverlaySuccess
                open={isCheckupUploaded}
                message={"อัพโหลดเรียบร้อยแล้ว"}
              />
            </span>
          </PaperStyled>
        </Box>
      </Box>

      <Box display="flex">
        <Box width="375px" pt={2} pr={2} order={2} display="inline-block">
          <Paper>
            <Wrapper mobile>
              {activeUser && activeHospital && (
                <CheckupMain
                  isDrawerShow={false}
                  profile={{ name: activeUser.name }}
                  hospital={activeHospital}
                  checkup={AdminTransform.toCreateUsersCheckup(
                    null,
                    activeHospital,
                    activeUser
                  )}
                  xrayLinks={[]}
                  dicomLinks={[]}
                />
              )}
            </Wrapper>
          </Paper>
        </Box>

        <Box
          flexGrow={1}
          pt={2}
          pl={2}
          pr={2}
          order={1}
          display="inline-block"
          style={{ overflow: "auto" }}
        >
          <Paper>
            <Wrapper table>
              <Table
                data={users}
                onItemClick={handleTableClick}
                loading={false}
              />
            </Wrapper>
          </Paper>
        </Box>
      </Box>
    </Loading>
  )
}
