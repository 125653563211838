import _ from "lodash"
import Config from "../../config"
import Transform from "./admin.transform"
import {
  PromiseUtils,
  ChunkUtils,
  ArrayUtils,
  FirestoreUtils,
} from "../../utils"
import { ApiManager, StorageManager } from "../../managers"

class AdminRepository {
  constructor({ firebase, cache }) {
    this._cache = cache
    this._firestore = firebase.firestore()
  }

  login({ username, password }) {
    return ApiManager.adminLogin({
      username,
      password,
    })
  }

  createHospital(hospital) {
    return new Promise(async (resolve, reject) => {
      const db = this._firestore

      const [fetchErr, fetchResult] = await PromiseUtils.to(
        db
          .collection(Config.FS_HOSPITAL_COLLECTION)
          .where("name", "==", hospital.name)
          .get()
      )

      if (fetchResult.size > 0) {
        reject("The hospital is exist")
        return
      }

      db.collection(Config.FS_HOSPITAL_COLLECTION)
        .doc()
        .set(hospital)
        .then(result => resolve(result))
        .catch(err => reject(err))
    })
  }

  createUsers(hospital, users = []) {
    return new Promise(async (resolve, reject) => {
      Promise.all(
        _.concat(
          this._createUsers(users),
          this._createUsersCredential(users),
          this._createUsersCheckup(hospital, users)
        )
      )
        .then(result => resolve(result))
        .catch(error => reject(error))
    })
  }

  createPreCheckup(hospital, users = []) {
    return new Promise(async (resolve, reject) => {
      Promise.all(
        _.concat(
          this._createUsers(users),
          this._createUsersCredential(users),
          this._createUsersPreCheckup(hospital, users)
        )
      )
        .then(result => resolve(result))
        .catch(error => reject(error))
    })
  }

  getHospital(hospitalId) {
    return new Promise(async (resolve, reject) => {
      const db = this._firestore

      const [fetchErr, fetchResult] = await PromiseUtils.to(
        db
          .collection(Config.FS_HOSPITAL_COLLECTION)
          .doc(hospitalId)
          .get()
      )

      if (fetchErr) {
        reject(fetchErr)
        return
      }

      if (!fetchResult.exists) {
        reject("The hospital is not exist")
        return
      }

      resolve({
        id: fetchResult.id,
        ...fetchResult.data(),
      })
    })
  }

  getHospitalsByAdmin(username) {
    return new Promise(async (resolve, reject) => {
      const db = this._firestore

      const [fetchErr, fetchResult] = await PromiseUtils.to(
        db
          .collection(Config.FS_ADMIN_CREDENTIAL_COLLECTION)
          .doc(username)
          .get()
      )

      if (fetchErr) {
        reject(fetchErr)
        return
      }

      if (!fetchResult.exists) {
        reject("The user is not exist")
        return
      }

      const [hospitalsErr, hospitalsResult] = await PromiseUtils.to(
        Promise.all(fetchResult.data().hospitals.map(item => item.get()))
      )

      if (hospitalsErr) {
        reject(hospitalsErr)
        return
      }

      const hospitals = hospitalsResult.map(item => {
        return {
          id: item.id,
          ...item.data(),
        }
      })

      resolve(hospitals)
    })
  }

  updateHospital({ id, data }) {
    return new Promise(async (resolve, reject) => {
      const db = this._firestore

      const updated = db
        .collection(Config.FS_HOSPITAL_COLLECTION)
        .doc(id)
        .set(data, { merge: true })

      resolve(updated)
    })
  }

  deleteUserCheckup({ hospital = "", checkupDate = "" }) {
    return new Promise(async (resolve, reject) => {
      const db = this._firestore
      const batch = db.batch()

      const [fetchErr, fetchResult] = await PromiseUtils.to(
        db
          .collection(Config.FS_CHECKUP_COLLECTION)
          .where("hospital", "==", db.doc(hospital))
          .where("checkup_date", "==", checkupDate)
          .get()
      )

      if (fetchErr) {
        reject(fetchErr)
        return
      }

      fetchResult.forEach(doc => {
        batch.delete(doc.ref)
      })

      const [batchErr, batchResult] = await PromiseUtils.to(batch.commit())

      if (batchErr) {
        reject(batchErr)
        return
      }

      resolve(fetchResult)
    })
  }

  createPreviewCheckup(data) {
    return new Promise((resolve, reject) => {
      const db = this._firestore

      db.collection(Config.FS_PREVIEW_CHECKUP_COLLECTION)
        .doc()
        .set(data)
        .then(result => resolve(result))
        .catch(err => reject(err))
    })
  }

  getUsersCheckup({ hospitalId, checkupDate }) {
    return new Promise(async (resolve, reject) => {
      const db = this._firestore
      const hospital = FirestoreUtils.wrapper({ firestore: db })
        .collection(Config.FS_HOSPITAL_COLLECTION)
        .doc(hospitalId)

      const [fetchErr, fetchResult] = await PromiseUtils.to(
        FirestoreUtils.wrapper({ firestore: db })
          .collection(Config.FS_CHECKUP_COLLECTION)
          .where({
            col: "hospital",
            operator: "==",
            value: hospital,
          })
          .where({
            col: "checkup_date",
            operator: "==",
            value: checkupDate,
            ignoreBy: _.isEmpty(checkupDate),
          })
          .get()
      )

      if (fetchErr) {
        reject(fetchErr)
        return
      }

      const [, users] = await PromiseUtils.to(
        Promise.all(fetchResult.docs.map(item => item.data().user.get()))
      )

      const checkups = fetchResult.docs.map((item, index) => ({
        ..._.omit(item.data(), ["hospital"]),
        user: users[index].data(),
      }))

      resolve(checkups)
    })
  }

  getUsersCheckupBy({
    hospitalId,
    checkupDate,
    patientId,
    startAt = 1,
    limit = 20,
  }) {
    return new Promise(async (resolve, reject) => {
      const db = this._firestore
      const cache = this._cache
      const cacheUsersCheckup = cache.getCacheUsersCheckup({
        hospitalId,
        checkupDate,
        patientId,
        startAt,
      })

      const hospital = FirestoreUtils.wrapper({ firestore: db })
        .collection(Config.FS_HOSPITAL_COLLECTION)
        .doc(hospitalId)

      const user = FirestoreUtils.wrapper({ firestore: db })
        .collection(Config.FS_USER_COLLECTION)
        .doc(patientId)

      const [fetchErr, fetchResult] = await PromiseUtils.to(
        FirestoreUtils.wrapper({ firestore: db })
          .collection(Config.FS_CHECKUP_COLLECTION)
          .where({
            col: "hospital",
            operator: "==",
            value: hospital,
          })
          .where({
            col: "checkup_date",
            operator: "==",
            value: checkupDate,
            ignoreBy: _.isEmpty(checkupDate),
          })
          .where({
            col: "user",
            operator: "==",
            value: user,
            ignoreBy: _.isEmpty(patientId),
          })
          .startAfter({
            doc: _.get(cacheUsersCheckup, "startAfter"),
            ignoreBy: _.isEmpty(cacheUsersCheckup),
          })
          .limit(limit)
          .get()
      )

      if (fetchErr) {
        reject(fetchErr)
        return
      }

      if (!_.isEmpty(fetchResult.docs)) {
        cache.cacheUsersCheckup({
          hospitalId,
          checkupDate,
          patientId,
          startAt: startAt + limit,
          startAfter: _.last(fetchResult.docs),
        })
      }

      const [, users] = await PromiseUtils.to(
        Promise.all(fetchResult.docs.map(item => item.data().user.get()))
      )

      const checkups = fetchResult.docs.map((item, index) => {
        const data = item.data()
        const medicalCheckups = data.medical_checkups.map(checkup => {
          return {
            [checkup.excel_col_checkup_note]: checkup.checkup_note,
            [checkup.excel_col_checkup_result]: checkup.checkup_suggestion,
            ...ArrayUtils.toObjectWithKeyValue(
              checkup.labs,
              "excel_col_name",
              "checkup_result"
            ),
          }
        })

        return {
          ...users[index].data(),
          date: data.checkup_date,
          ...ArrayUtils.toObject(medicalCheckups),
        }
      })

      resolve(checkups)
    })
  }

  logout() {
    return new Promise((resolve, reject) => {
      StorageManager.clearAdmin()
      resolve()
    })
  }

  saveCredentials({ token }) {
    StorageManager.saveAdminCredential(token)
  }

  getCredentials() {
    return {
      uid: StorageManager.getAdminUID(),
    }
  }

  _createUsers(users = []) {
    return ChunkUtils.chunk(Transform.toCreateUsers(users)).map(setOfUsers =>
      this._createUser(setOfUsers)
    )
  }

  _createUsersCredential(users = []) {
    return ChunkUtils.chunk(users).map(setOfUsers =>
      this._createUserCredential(setOfUsers)
    )
  }

  _createUsersCheckup(hospital, users = []) {
    return users.map(user => this._createUserCheckup(hospital, user))
  }

  _createUsersPreCheckup(hospital, users = []) {
    return ChunkUtils.chunk(
      Transform.toCreatePreCheckups({
        users,
        hospitalId: hospital.id,
      })
    ).map(setOfUsers => this._createPreCheckup(setOfUsers))
  }

  _createUser(users = []) {
    return new Promise((resolve, reject) => {
      const db = this._firestore
      const batch = db.batch()

      _.forEach(users, user => {
        const ref = db.collection(Config.FS_USER_COLLECTION).doc(user.idcard)

        batch.set(ref, user)
      })

      batch
        .commit()
        .then(() => resolve())
        .catch(err => reject(err))
    })
  }

  _createUserCredential(users = []) {
    return new Promise((resolve, reject) => {
      const db = this._firestore
      const batch = db.batch()

      _.forEach(users, user => {
        const credential = Transform.toCreateUsersCredential(db, user)
        const ref = db
          .collection(Config.FS_USER_CREDENTIAL_COLLECTION)
          .doc(user.idcard)

        batch.set(ref, credential)
      })

      batch
        .commit()
        .then(() => resolve())
        .catch(err => reject(err))
    })
  }

  _createUserCheckup(hospital, user) {
    return new Promise((resolve, reject) => {
      const db = this._firestore
      const userCheckup = Transform.toCreateUsersCheckup(db, hospital, user)

      db.collection(Config.FS_CHECKUP_COLLECTION)
        .doc(userCheckup.second_id)
        .set(userCheckup)
        .then(() => resolve())
        .catch(err => reject(err))
    })
  }

  _createPreCheckup(users) {
    return new Promise((resolve, reject) => {
      const db = this._firestore
      const batch = db.batch()

      _.forEach(users, user => {
        const ref = db.collection(Config.FS_PRE_CHECKUP_COLLECTION).doc()
        batch.set(ref, user)
      })

      batch
        .commit()
        .then(() => resolve())
        .catch(err => reject(err))
    })
  }
}

export default AdminRepository
