import MD5 from "js-md5"

const _inMemCacheUsersCheckup = {}
const _getKey = ({ hospitalId, checkupDate, patientId, startAt }) => MD5(`${hospitalId}${checkupDate}${patientId}${startAt}`)

export default {
  getCacheUsersCheckup: (props) => _inMemCacheUsersCheckup[_getKey(props)],
  cacheUsersCheckup: (props) => {
    const { startAfter } = props
    _inMemCacheUsersCheckup[_getKey(props)] = {
      startAfter,
    }
  },
}
