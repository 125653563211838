import React from "react"
import _ from "lodash"
import PropTypes from "prop-types"
import styled from "styled-components"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  CircularProgress,
  Box,
  Typography,
} from "@material-ui/core"
import {
  KeyboardArrowLeft,
  KeyboardArrowRight
} from "@material-ui/icons"
import { ShortText } from "./index"

const TableWrapper = styled.div`
  max-height: 72vh;
  overflow: auto;
`

const BoxStyled = styled(Box)`
  height: 20vh;
`

const tablePaginationActions = ({ page, onChangePage, isPrevious, isNext }) => {
  const handleBackButtonClick = event => {
    onChangePage(event, page - 1)
  }

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1)
  }

  return (
    <React.Fragment>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={!isPrevious}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={!isNext}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
    </React.Fragment>
  )
}

const Component = props => {
  const {
    data = [],
    rowsPerPage = 20,
    onItemClick,
    onChangePage,
    totalItem,
    loading = true,
  } = props
  const [page, setPage] = React.useState(0)
  const [pageHistory, setPageHistory] = React.useState({ 1: 1 })
  const firstRow = data && data.length > 0 && data[0]
  const headerRows = Object.keys(firstRow)
  const bodyRows =
    data &&
    data.length > 0 &&
    data
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map(item => Object.values(item))
  const count = totalItem || (data && data.length)
  const labelDisplayedRows = ({ from, to, count }) =>
    `${from}-${to === -1 ? count : to}`

  const handleChangePage = (event, page) => {
    const newPage = page + 1
    setPageHistory({
      ...pageHistory,
      [newPage]: newPage,
    })
    setPage(page)

    onChangePage &&
      onChangePage({
        rowsPerPage,
        page: newPage,
        isChanged: !pageHistory[newPage],
      })
  }

  if (loading) {
    return (
      <BoxStyled display="flex" justifyContent="center" alignItems="center">
        <CircularProgress disableShrink />
      </BoxStyled>
    )
  }

  if (!loading && _.isEmpty(bodyRows)) {
    return (
      <BoxStyled display="flex" justifyContent="center" alignItems="center">
        <Typography variant="h5">ไม่มีข้อมูล</Typography>
      </BoxStyled>
    )
  }

  return (
    <React.Fragment>
      <TableWrapper>
        <Table stickyHeader {...props}>
          <TableHead>
            <TableRow>
              {headerRows.map((item, indexCell) => (
                <TableCell key={indexCell} align="right">
                  <ShortText text={item} />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {bodyRows.map((item, index) => (
              <TableRow
                hover
                key={index}
                onClick={() => onItemClick({ item, index })}
              >
                {item.map((item, indexCell) => (
                  <TableCell key={indexCell} align="right">
                    <ShortText text={item} />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableWrapper>
      <TablePagination
        count={count}
        labelDisplayedRows={labelDisplayedRows}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[rowsPerPage]}
        page={page}
        onChangePage={handleChangePage}
        ActionsComponent={props =>
          tablePaginationActions({
            ...props,
            isPrevious: props.page !== 0,
            isNext: bodyRows.length === rowsPerPage,
          })
        }
      />
    </React.Fragment>
  )
}

Component.propTypes = {
  data: PropTypes.array.isRequired,
  onItemClick: PropTypes.func,
  onChangePage: PropTypes.func,
  rowsPerPage: PropTypes.number.isRequired,
  totalItem: PropTypes.number.isRequired,
}

export default Component
