import React, { useEffect } from "react"
import { createGlobalStyle } from 'styled-components'
import { FirebaseAuth } from "../containers"
import { FirebaseManager } from "../managers"

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0px;
  }
`

export const AppContext = React.createContext()

export default ({ children }) => {
  const [store, setStore] = React.useState({})

  useEffect(() => {
    const app = import("firebase/app")
    const auth = import("firebase/auth")
    const database = import("firebase/firestore")

    Promise.all([app, auth, database]).then(values => {
      const [firebaseApp] = values
      const firebase = FirebaseManager.getInstance(firebaseApp)
      setStore({
        ...store,
        firebase,
      })
    })
  }, [])

  if (!store.firebase) {
    return <div />
  }

  return (
    <AppContext.Provider value={{ store, setStore }}>
      <GlobalStyle />
      <FirebaseAuth>{children}</FirebaseAuth>
    </AppContext.Provider>
  )
}
