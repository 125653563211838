export default {
  FS_USER_COLLECTION: `${process.env.GATSBY_FS_USER_COLLECTION}`,
  FS_USER_CREDENTIAL_COLLECTION: `${process.env.GATSBY_FS_USER_CREDENTIAL_COLLECTION}`,
  FS_HOSPITAL_COLLECTION: `${process.env.GATSBY_FS_HOSPITAL_COLLECTION}`,
  FS_CHECKUP_COLLECTION: `${process.env.GATSBY_FS_CHECKUP_COLLECTION}`,
  FS_ADMIN_CREDENTIAL_COLLECTION: `${process.env.GATSBY_FS_ADMIN_CREDENTIAL_COLLECTION}`,
  FS_PREVIEW_CHECKUP_COLLECTION: `${process.env.GATSBY_FS_PREVIEW_CHECKUP_COLLECTION}`,
  FS_PRE_CHECKUP_COLLECTION: `${process.env.GATSBY_FS_PRE_CHECKUP_COLLECTION}`,
  API_USER_LOGIN: `${process.env.GATSBY_API_USER_LOGIN}`,
  API_USER_XRAY: `${process.env.GATSBY_API_USER_XRAY}`,
  API_USER_DICOM: `${process.env.GATSBY_API_USER_DICOM}`,
  API_ADMIN_LOGIN: `${process.env.GATSBY_API_ADMIN_LOGIN}`,
  API_VERIFY_LOGIN: `${process.env.GATSBY_API_VERIFY_LOGIN}`,
  COOKIE: {
    USER_TOKEN: `${process.env.GATSBY_COOKIE_USER_TOKEN}`,
    ADMIN_TOKEN: `${process.env.GATSBY_COOKIE_ADMIN_TOKEN}`,
    USER_PRIVACY_AGREEMENT: `${process.env.GATSBY_COOKIE_USER_PRIVACY_AGREEMENT}`,
  },
}
