import React from "react"
import { navigate } from "gatsby"
import { AdminFactory } from "../business/admin"
import { AppContext } from "../components/layout.component"

export default WrappedComponent => {
  return class extends React.PureComponent {
    static contextType = AppContext

    constructor(props) {
      super(props)
      this.state = {
        isLoading: true,
        isLogin: false,
      }
    }

    componentDidMount() {
      const { store } = this.context

      if (!this._service) {
        this._service = AdminFactory.getService(store.firebase)
      }

      if (!this._service.isLogin()) {
        this.setState({
          isLoading: false,
          isLogin: false,
        })
        return
      }

      this.setState({
        isLoading: false,
        isLogin: true,
      })
    }

    render() {
      if (this.state.isLoading) {
        return <div></div>
      }

      if (!this.state.isLogin) {
        navigate("login")
        return <div></div>
      }

      return <WrappedComponent {...this.props} />
    }
  }
}
