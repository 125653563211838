const config = {
  apiKey: `${process.env.GATSBY_FIREBASE_API_KEY}`,
  authDomain: `${process.env.GATSBY_FIREBASE_AUTH_DOMAIN}`,
  projectId: `${process.env.GATSBY_FIREBASE_PROJECT_ID}`,
}

let firebaseInstance

const getInstance = firebase => {
  if (firebaseInstance) {
    return firebaseInstance
  }

  firebase.initializeApp(config)
  firebaseInstance = firebase

  return firebaseInstance
}

export default {
  getInstance,
}
