import React, { useState, useEffect, useContext } from "react"
import { AdminFactory } from "../business/admin"
import { AuthenFactory } from "../business/authentication"
import { AppContext } from "../components/layout.component"

export default () => {
  const { store } = useContext(AppContext)
  const [values, setValues] = useState({
    username: "",
    password: "",
    pincode: "",
  })
  const [isLogin, setLogin] = useState(null)
  const [isVerify, setVerify] = useState(null)
  const [isLoginSuccess, setLoginSuccess] = useState(null)
  const [isVerifySuccess, setVerifySuccess] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (isLogin) {
      const { username, password } = values
      const service = AdminFactory.getService(store.firebase)

      service
        .login({ username, password })
        .then(token => {
          setLoginSuccess(true)
        })
        .catch(err => {
          setLogin(null)
          setVerify(null)
          setError(String(err))
        })
    }
  }, [isLogin])

  useEffect(() => {
    if (isVerify) {
      const { username, pincode } = values
      const service = AuthenFactory.getService()

      service
        .verify({ username, pincode })
        .then(result => {
          setLoginSuccess(true)
          setVerifySuccess(true)
          setLogin(null)
          setVerify(null)
          setError(null)
        })
        .catch(err => {
          setLogin(null)
          setVerify(null)
          setError(String(err))
        })
    }
  }, [isVerify])

  const handleUsernameChange = event => {
    setValues({ ...values, username: event.target.value })
  }

  const handlePasswordChange = event => {
    setValues({ ...values, password: event.target.value })
  }

  const handlePincodeChange = event => {
    setValues({ ...values, pincode: event.target.value })
  }

  const handleSubmit = () => {
    setLogin(true)
    setError(null)
    setLoginSuccess(null)
    setVerifySuccess(null)
  }

  const handleVerify = () => {
    setVerify(true)
    setError(null)
    setLoginSuccess(null)
    setVerifySuccess(null)
  }

  return {
    values,
    handleUsernameChange,
    handlePasswordChange,
    handlePincodeChange,
    isLogin,
    isLoginSuccess,
    isVerifySuccess,
    error,
    submitLogin: handleSubmit,
    submitVerify: handleVerify,
  }
}
