import React, { useState } from "react"
import { navigate } from "gatsby"
import { withAuthenticate } from "../common/hoc"
import { Layout } from "../common/components"
import { Appbar, Drawer } from "../common/components"
import Main from "../modules/checkup-preview/main"

const CheckupPreviewPage = () => {
  const [drawerState, setDrawerState] = useState({ open: false })

  const handleNavigationClick = () => {
    setDrawerState({ open: true })
  }

  const handleLogoutClick = () => {
    navigate("logout")
  }

  return (
    <Layout>
      <Appbar
        onNavigationClick={handleNavigationClick}
        onLogoutClick={handleLogoutClick}
      />
      <Drawer open={drawerState} />
      <Main />
    </Layout>
  )
}

export default withAuthenticate(CheckupPreviewPage)
