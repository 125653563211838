import Config from "../../config"
import moment from "moment"
import MD5 from "js-md5"
import get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import PreCheckupTemplate from "../../../../template/pre_checkup.json"

export default {
  toPrepareCreateUsers: ({ users, hospital }) => {
    const { mapping } = hospital
    const enhanceReplace = (value, replaces) => {
      return replaces.reduce(
        (accumulator, replace) =>
          accumulator.replace(replace.found, replace.new),
        `${value}`
      )
    }
    return users
      .filter(user => {
        const accession = user[mapping.accession.key]
        return (
          accession !== undefined &&
          accession !== null &&
          accession !== "" &&
          accession !== "-"
        )
      })
      .map(user => {
        const accession = `${get(
          user,
          mapping.accession.key,
          mapping.accession.defaultValue
        )}`
        const password = `${get(
          user,
          mapping.password.key,
          mapping.password.defaultValue
        )}`
        const name = `${get(user, mapping.name.key, mapping.name.defaultValue)}`
        const age = `${get(user, mapping.age.key, mapping.age.defaultValue)}`
        const height = `${get(
          user,
          mapping.height.key,
          mapping.height.defaultValue
        )}`
        const weight = `${get(
          user,
          mapping.weight.key,
          mapping.weight.defaultValue
        )}`
        const phone = `${get(
          user,
          mapping.phone.key,
          mapping.phone.defaultValue
        )}`

        return {
          ...user,
          idcard: enhanceReplace(accession, mapping.accession.replaces),
          password: enhanceReplace(password, mapping.password.replaces),
          name: enhanceReplace(name, mapping.name.replaces),
          age: enhanceReplace(age, mapping.age.replaces),
          height: enhanceReplace(height, mapping.height.replaces),
          weight: enhanceReplace(weight, mapping.weight.replaces),
          phone: enhanceReplace(phone, mapping.phone.replaces),
        }
      })
  },
  toCreateUsers: users => {
    return users.map(user => {
      return {
        name: user.name,
        age: user.age,
        idcard: user.idcard,
        height: user.height,
        weight: user.weight,
        phone: user.phone,
      }
    })
  },
  toCreateUsersCredential: (db, user) => {
    return {
      username: user.idcard,
      password: user.password,
      users: db.doc(`${Config.FS_USER_COLLECTION}/${user.idcard}`),
    }
  },
  toCreateUsersCheckup: (db, hospital, user) => {
    const isDBIntialized = db !== undefined && db !== null
    const medicalCheckups = hospital.medical_checkups.map(medicalCheckup => {
      const labs = medicalCheckup.labs.map(lab => {
        return {
          ...lab,
          checkup_result:
            user[lab.excel_col_name] === undefined
              ? ""
              : user[lab.excel_col_name],
        }
      })

      const checkupNote =
        user[medicalCheckup.excel_col_checkup_result] === undefined
          ? ""
          : user[medicalCheckup.excel_col_checkup_result]
      const checkupSuggestion =
        user[medicalCheckup.excel_col_checkup_note] === undefined
          ? ""
          : user[medicalCheckup.excel_col_checkup_note]

      return {
        ...medicalCheckup,
        labs,
        checkup_date_original: user[medicalCheckup.excel_col_checkup_date],
        checkup_note: checkupNote,
        checkup_suggestion: checkupSuggestion,
        create_at: new Date(moment.utc().format()),
      }
    })

    return {
      second_id: isDBIntialized
        ? MD5(
            `${hospital.id}-${user.idcard}-${medicalCheckups[0].checkup_date_original}`
          )
        : "",
      hospital: isDBIntialized
        ? db.doc(`${Config.FS_HOSPITAL_COLLECTION}/${hospital.id}`)
        : "",
      user: isDBIntialized
        ? db.doc(`${Config.FS_USER_COLLECTION}/${user.idcard}`)
        : "",
      checkup_date: medicalCheckups[0].checkup_date_original,
      medical_checkups: medicalCheckups,
      create_at: new Date(moment.utc().format()),
    }
  },
  toCreatePreCheckups: ({ hospitalId = "", users = [] }) => {
    return users
      .filter(user => !isEmpty(user.Accession))
      .map(user => {
        const checkupList = PreCheckupTemplate.checkupList.map(checkup => ({
          ...checkup,
          labs: checkup.labs.map(lab => ({
            ...lab,
            result: get(user, lab.excel_col_name) || "",
          })),
        }))

        return {
          userId: get(user, "Accession") || "",
          hospitalId: hospitalId,
          checkupDate: get(user, "Date") || "",
          checkupList: checkupList,
          checkupTimestamp: moment(get(user, "Date"), "DD/MM/YYYY").toDate(),
        }
      })
  },
}
