import React, { useState, useEffect, useContext } from "react"
import { PromiseUtils } from "../utils"
import { AdminFactory } from "../business/admin"
import { AppContext } from "../components/layout.component"

const upload = ({ service, hospitalId = "", users = [] }) => {
  return new Promise(async (resolve, reject) => {
    const [err, result] = await PromiseUtils.to(
      service.createPreCheckup(hospitalId, users)
    )

    if (err) {
      reject(err)
      return
    }

    resolve(result)
  })
}

export default () => {
  const { store } = useContext(AppContext)
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [isUploaded, setUploaded] = useState(false)

  useEffect(() => {
    if (data) {
      const service = AdminFactory.getService(store.firebase)

      upload({ service, ...data })
        .then(result => setUploaded(true))
        .catch(err => console.error(err))
    }
  }, [data])

  return {
    isCheckupUploaded: isUploaded,
    error: error,
    uploadPreCheckup: ({ hospitalId = "", users = [] }) =>
      setData({ hospitalId, users }),
  }
}
