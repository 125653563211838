import React, { useState, useEffect } from "react"
import XLSX from "xlsx"

const getDataFromFile = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = e => {
      const data = e.target.result

      const workbook = XLSX.read(data, {
        type: "binary",
      })

      if (workbook.SheetNames.length === 0) {
        return
      }

      const firstSheetName = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[firstSheetName]
      const items = XLSX.utils.sheet_to_json(worksheet)

      resolve(items)
    }

    reader.readAsBinaryString(file)
  })
}

export default ({beforeDataLoaded}) => {
  const [data, setData] = useState([])
  const [file, setFile] = useState(null)
  const [isLoadData, setLoadData] = useState(false)

  useEffect(() => {
    if (file && isLoadData) {
      (async () => {
        try {
          const result = await getDataFromFile(file)
          const transformed = beforeDataLoaded(result)
          setData(transformed)
          setLoadData(false) 
        } catch (err) {
          console.error(err)
        }
      })();
    }
  }, [file, isLoadData])

  const clearState = () => {
    setData([])
    setFile(null)
    setLoadData(false)
  }

  return {
    file,
    excelData: data,
    chooseExcelFile: excelFile => setFile(excelFile),
    loadExcelData: () => setLoadData(true),
    clear: clearState,
  }
}
