import React, { useState, useContext } from "react"
import { AppContext } from "../components/layout.component"
import { PromiseUtils } from "../utils"
import { AdminFactory } from "../business/admin"

const getUsersCheckupBy = ({service, ...rest}) => {
  return new Promise(async (resolve, reject) => {
    const [err, result] = await PromiseUtils.to(
      service.getUsersCheckupBy(rest)
    )

    if (err) {
      reject(err)
      return
    }

    const [users] = result

    resolve({
      users
    })
  })
}

export default () => {
  const { store } = useContext(AppContext)
  const [values, setValues] = useState([])
  const service = AdminFactory.getService(store.firebase)

  const get = (props) => {
    getUsersCheckupBy({service, ...props})
      .then(result => setValues(result))
      .catch(err => console.error(err))
  }

  return {
    values,
    get
  }
}
