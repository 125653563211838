import React, { useEffect } from "react"
import { useLogout } from "../../common/hooks"
import { navigate } from "gatsby"

export default () => {
  const { isLogout, error, logout } = useLogout()

  useEffect(() => {
    logout()
  }, [])

  useEffect(() => {
    if (isLogout) {
      navigate("login")
    }
  }, [isLogout])

  return <></>
}
