import { AuthenService } from "./index"

let _service

export default {
  getService: () => {
    if (_service) {
      return _service
    }
    return (_service = new AuthenService())
  },
}
