import React, { useState, useContext } from "react"
import { AdminFactory } from "../business/admin"
import { AppContext } from "../components/layout.component"

export default () => {
  const { store } = useContext(AppContext)
  const [isLogout, setLogout] = useState(false)
  const [error, setError] = useState(null)

  const _logout = () => {
    const service = AdminFactory.getService(store.firebase)

    service
      .logout()
      .then(result => setLogout(true))
      .catch(err => setError(err))
  }

  return {
    isLogout,
    error,
    logout: () => _logout(),
  }
}
