import { AdminService, AdminRepository, AdminCache } from "./index"

let _service

export default {
  getService: firebase => {
    if (_service) {
      return _service
    }
    return (_service = new AdminService(
      new AdminRepository({ firebase, cache: AdminCache })
    ))
  },
}
