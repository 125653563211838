import axios from "axios"
import { PromiseUtils } from "../utils"
import Config from "../config"

export default {
  userLogin: ({ username, password }) => {
    return new Promise(async (resolve, reject) => {
      const [err, data] = await PromiseUtils.to(
        axios.post(Config.API_USER_LOGIN, {
          username,
          password,
        })
      )

      if (err) {
        reject(err)
        return
      }

      const result = data.data

      if (!result.success) {
        reject(result.message)
        return
      }

      resolve(result.data.accessToken)
    })
  },
  adminLogin: ({ username, password }) => {
    return new Promise(async (resolve, reject) => {
      const [err, data] = await PromiseUtils.to(
        axios.post(Config.API_ADMIN_LOGIN, {
          username,
          password,
        })
      )

      if (err) {
        reject(err)
        return
      }

      const result = data.data

      if (!result.success) {
        reject(result.message)
        return
      }

      resolve(result.data.accessToken)
    })
  },
  userXray: ({ hospital = "", name = "", date = "" }) => {
    return new Promise(async (resolve, reject) => {
      const [err, data] = await PromiseUtils.to(
        axios.post(Config.API_USER_XRAY, {
          hospital,
          name,
          date,
        })
      )

      if (err) {
        reject(err)
        return
      }

      const result = data.data

      if (!result.success) {
        reject(result.message)
        return
      }

      resolve(result.data)
    })
  },
  userDicom: ({ hospital = "", name = "", date = "" }) => {
    return new Promise(async (resolve, reject) => {
      const [err, data] = await PromiseUtils.to(
        axios.post(Config.API_USER_DICOM, {
          hospital,
          name,
          date,
        })
      )

      if (err) {
        reject(err)
        return
      }

      const result = data.data

      if (!result.success) {
        reject(result.message)
        return
      }

      resolve(result.data)
    })
  },
  verify: async ({ username, pincode, secretCode }) => {
    const [err, result] = await PromiseUtils.to(
      axios({
        url: Config.API_VERIFY_LOGIN,
        method: "post",
        data: {
          username,
          pincode,
          secretCode,
        },
      })
    )
    const { success, message, data } = result.data

    if (err) {
      throw err
    }

    if (!success) {
      throw message
    }

    return data;
  },
}
