import React, { useState, useEffect, useContext } from "react"
import { AppContext } from "../components/layout.component"
import { PromiseUtils } from "../utils"
import { AdminFactory } from "../business/admin"

const getHospitals = service => {
  return new Promise(async (resolve, reject) => {
    const [err, hospitals] = await PromiseUtils.to(service.getHospitals())

    if (err) {
      reject(err)
      return
    }

    resolve(hospitals)
  })
}

export default () => {
  const { store } = useContext(AppContext)
  const [hospitals, setHospitals] = useState([])

  useEffect(() => {
    const service = AdminFactory.getService(store.firebase)

    getHospitals(service)
      .then(hospitals => setHospitals(hospitals))
      .catch(err => console.error(err))
  }, [])

  return hospitals
}
