import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { IconButton, SnackbarContent } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"

const SnackbarSuccessStyled = styled(SnackbarContent)`
  background-color: #43a047;
`

export default ({ open = false, message = "" }) => {
  const [state, setState] = useState({
    open,
    message,
  })

  useEffect(() => {
    setState({
      ...state,
      open,
      message,
    })
  }, [open, message])

  const handleClose = e => {
    setState({
      ...state,
      open: false,
      message: "",
    })
  }

  if (!state.open) {
    return <span />
  }

  return (
    <SnackbarSuccessStyled
      aria-describedby="client-snackbar"
      message={<span id="client-snackbar">{state.message}</span>}
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>,
      ]}
    />
  )
}
