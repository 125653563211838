import JWT from "jsonwebtoken"
import Config from "../config"

export default {
  getUID: () => {
    const token = localStorage.getItem(Config.COOKIE.USER_TOKEN)

    if (!token) {
      return null
    }

    const decoded = JWT.decode(token)
    return decoded.uid
  },
  saveCredential: token => {
    localStorage.setItem(Config.COOKIE.USER_TOKEN, token)
  },
  clear: () => {
    localStorage.removeItem(Config.COOKIE.USER_TOKEN)
  },
  getAdminUID: () => {
    const token = localStorage.getItem(Config.COOKIE.ADMIN_TOKEN)

    if (!token) {
      return null
    }

    const decoded = JWT.decode(token)
    return decoded.uid
  },
  saveAdminCredential: token => {
    localStorage.setItem(Config.COOKIE.ADMIN_TOKEN, token)
  },
  clearAdmin: () => {
    localStorage.removeItem(Config.COOKIE.ADMIN_TOKEN)
  },
  savePrivacyAgreement: () => {
    localStorage.setItem(Config.COOKIE.USER_PRIVACY_AGREEMENT, true)
  },
  getPrivacyAgreement: () => {
    const isAgree = localStorage.getItem(Config.COOKIE.USER_PRIVACY_AGREEMENT)

    if (!isAgree) {
      return false
    }

    return true
  },
}
