import React, { useState } from "react"
import Dexie from "dexie"

const db = new Dexie("admin-aptnesshealth")
db.version(1).stores({ state: "id,value" })

export default (page = "") => {
  const [state, setState] = useState({})
  const [error, setError] = useState(null)

  const save = value => {
    db.state
      .put({
        id: page,
        value,
      })
      .then(() => get())
      .catch(err => setError(err))
  }

  const get = () => {
    db.state
      .get(page)
      .then(result => {
        setState({
          ...state,
          ...result,
        })
      })
      .catch(err => setError(err))
  }

  return {
    state,
    error,
    save,
    get,
  }
}
