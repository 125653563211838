import React, { useState, useContext } from "react"
import { AppContext } from "../components/layout.component"

export default ({ children }) => {
  const { store } = useContext(AppContext)
  const { firebase } = store
  const { currentUser } = firebase.auth()
  const [loading, setLoading] = useState(true)

  if (currentUser || !loading) {
    return <div>{children}</div>
  }

  firebase
    .auth()
    .signInAnonymously()
    .then(() => setLoading(false))
    .catch(err => console.error(err))

  return <div />
}
