import React, { useState, useEffect } from "react"
import _ from "lodash"
import styled from "styled-components"
import { Container, Card, CardContent, Button } from "@material-ui/core"
import { CloudUpload } from "@material-ui/icons"
import {
  Loading,
  InputFile,
  SnackbarWarning,
  SnackbarSuccess,
  Spacing,
  Select,
} from "../../common/components"
import {
  useHospitals,
  useReadExcel,
  useUploadCheckup,
  useDatabase,
} from "../../common/hooks"
import { ArrayUtils } from "../../common/utils"
import { AdminTransform } from "../../common/business/admin"
import { navigate } from "gatsby"

const CardStyled = styled(Card)`
  margin-top: 2vh;
`

const InputFileStyled = styled(InputFile)`
  display: block;
  margin-top: 2vh;
`

const ButtonStyled = styled(Button)`
  margin-top: 3vh;
  margin-right: 1vw;
`

const CloudUploadStyled = styled(CloudUpload)`
  margin-left: 1vw;
`

export default props => {
  const [activeHospital, setActiveHospital] = useState({ id: "" })
  const [loading, setLoading] = useState(true)
  const [isUpload, setUpload] = useState(false)
  const [isPreview, setPreview] = useState(false)
  const [success, setSuccess] = useState(null)
  const [error, setError] = useState(null)
  const hospitals = useHospitals()
  const {
    file: excelFile,
    clear: clearExcelState,
    excelData,
    chooseExcelFile,
    loadExcelData,
  } = useReadExcel({
    beforeDataLoaded: result => {
      return AdminTransform.toPrepareCreateUsers({
        users: result,
        hospital: activeHospital,
      })
    },
  })
  const { isCheckupUploaded, uploadCheckup } = useUploadCheckup()
  const { state: stateCheckupData, save: saveCheckupState } = useDatabase(
    "checkup-upload"
  )

  useEffect(() => {
    if (!_.isEmpty(hospitals)) {
      setActiveHospital(hospitals[0])
      setLoading(false)
    }
  }, [hospitals])

  useEffect(() => {
    if (isUpload && !_.isEmpty(excelData)) {
      uploadCheckup({
        hospitalId: activeHospital.id,
        users: excelData,
      })
    }
  }, [excelData, isUpload])

  useEffect(() => {
    if (stateCheckupData.id) {
      navigate("checkup-preview")
    }
  }, [stateCheckupData])

  useEffect(() => {
    if (isPreview && !_.isEmpty(excelData)) {
      setLoading(false)
      setPreview(false)
      saveCheckupState({
        users: excelData,
        hospital: activeHospital,
      })
    }
  }, [excelData, isPreview])

  useEffect(() => {
    if (isCheckupUploaded) {
      setLoading(false)
      setUpload(false)
      setPreview(false)
      clearExcelState()
      setSuccess(`${excelFile.name} อัพโหลดเรียบร้อยแล้ว`)
    }
  }, [isCheckupUploaded])

  useEffect(() => {
    if (loading && isUpload) {
      loadExcelData()
    }
  }, [loading, isUpload])

  useEffect(() => {
    if (loading && isPreview) {
      loadExcelData()
    }
  }, [loading, isPreview])

  const handleChangeHospital = e => {
    const hospital = _.find(hospitals, { id: e.target.value })
    setActiveHospital(hospital)
    setError(null)
    setSuccess(null)
  }

  const handleChangeFile = e => {
    const { files } = e.target
    const [file = null] = files

    chooseExcelFile(file)
    setError(null)
    setSuccess(null)
  }

  const handleUpload = () => {
    setLoading(true)
    setUpload(true)
  }

  const handlePreview = () => {
    setLoading(true)
    setPreview(true)
  }

  return (
    <Loading loading={loading}>
      <Container maxWidth="sm">
        <CardStyled>
          <CardContent>
            <SnackbarWarning open={error ? true : false} message={error} />
            <SnackbarSuccess open={success ? true : false} message={success} />
            <Spacing />

            <Select
              label="เลือกโรงพยาบาล"
              items={ArrayUtils.toObjectWithKeyValue(hospitals, "id", "name")}
              defaultValue={activeHospital.id}
              onChange={handleChangeHospital}
            />
            <br />

            <InputFileStyled onChange={handleChangeFile} />

            <ButtonStyled
              variant="contained"
              color="default"
              onClick={handleUpload}
              disabled={!excelFile}
            >
              อัพโหลด
              <CloudUploadStyled />
            </ButtonStyled>

            <ButtonStyled
              variant="contained"
              color="primary"
              onClick={handlePreview}
              disabled={!excelFile}
            >
              Preview
            </ButtonStyled>
          </CardContent>
        </CardStyled>
      </Container>
    </Loading>
  )
}
