import React from "react"
import Tooltip from '@material-ui/core/Tooltip'

export default ({ text = "" }) => {
  const msg = `${text}`
  if (msg.length > 10) {
    return (
      <Tooltip title={msg}>
        <span>{`${msg.substring(0, 10)}...`}</span>
      </Tooltip>
    )
  }

  return <span>{msg}</span>
}
