export default {
  toObject: (array = []) => {
    return Object.assign({}, ...array.map(item => item))
  },
  toObjectWithKeyValue: (array = [], key, value) => {
    if (value === undefined || value === null || value === "") {
      return Object.assign({}, ...array.map(item => ({ [item[key]]: item })))
    }

    return Object.assign(
      {},
      ...array.map(item => ({ [item[key]]: item[value] }))
    )
  },
}
